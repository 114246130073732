<template>
  <div class="mm-product-actions">
    <CompareButton
      v-if="visibleCompare"
      text
      class="mm-product-actions__item mm-product-actions-compare"
      :active="isComparison"
      @add="$emit('compare:add')"
      @open="$emit('compare:open')"
    />
    <CopyLink
      class="mm-product-actions__item"
      :copy-url="copyUrl"
      :oci-mode="ociMode"
    />
  </div>
</template>

<script lang="ts" setup>
import CopyLink from './CopyLink.vue';
import CompareButton from 'shared/components/CompareButton.vue';
import { IProductDetails } from 'shared/models/product.model';

const props = defineProps<{
  productDetails: IProductDetails;
  visibleCompare?: boolean;
  ociMode?: boolean;
  isComparison?: boolean;
}>();

defineEmits<{
  (e: 'compare:add'): void;
  (e: 'compare:open'): void;
}>();

const copyUrl = computed(
  () => props.ociMode
    ? `/oci${props.productDetails?.slug}`
    : props.productDetails?.slug,
);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-product-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  .btn {
    padding: 0;
  }

  &__item {
    &:not(&:last-child) {
      border-right: 1px solid $dark-gray;
      padding-right: 20px;
      margin-right: 22px;
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-product-actions {
    justify-content: flex-start;

    &__item {
      &:not(&:last-child) {
        margin-right: 20px;
        border: none;
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-product-actions {
    justify-content: flex-start;

    &-compare {
      :deep(.mm-compare-btn__icon) {
        margin: 0;
      }

      :deep(.mm-compare-btn__text) {
        display: none;
      }
    }
  }
}
</style>
