<template>
  <div
    v-if="userCity"
    class="offers-table-title"
  >
    {{ isShowPrice ? 'Цены на' : 'Предложения товара' }} {{ productDetails?.name }} для
    <span v-if="!userBasis">{{ userCity }}</span>
    <span v-else>{{ userBasis?.name }}</span>
  </div>
</template>

<script lang="ts" setup>
import { IDeliveryBase } from 'shared/models/bases.model';
import { IProductDetails } from 'shared/models/product.model';
import BasesService from 'services/basesManager.service';

defineProps<{
  productDetails: IProductDetails;
  userBasis: Partial<IDeliveryBase>;
  isShowPrice?: boolean;
}>();

const basesManager = inject<BasesService>(BasesService.getServiceName());

const userCity = computed<string>(() => basesManager?.city ?? '');
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.offers-table-title {
  padding: 5px 32px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: $light-green;
  margin-bottom: 27px;
}
</style>
