import { orderBy } from 'lodash-es';
import { IUseProductOffers, IUseProductOffersParams } from 'models/composables/useProductOffers.model';
import { IProductResultedOffer } from 'shared/models/product.model';
import { ESortState } from 'shared/enums/sortState.enum';
import { ITableSort } from 'shared/models/table.model';
import { EProductSortField } from 'enums/EProductSortField.enum';
import { EOfferCalcTypes } from 'shared/enums/offerCalcTypes.enum';

export function useProductOffers(params: IUseProductOffersParams): IUseProductOffers {
  const currentOverallPrice = computed(() =>
    OfferMapper.getCurrentOverallPrice(
      undefined,
      params.selectedCalcType?.value?.value as Array<EOfferCalcTypes> || [],
    ),
  );

  const offersSortState = ref<ITableSort>({
    field: currentOverallPrice.value?.type,
    order: ESortState.Asc,
  });

  const cleanOffersItems = computed(() =>
    ((params?.offersData?.value?.items as unknown as Array<IProductResultedOffer>) || [])
      .sort((a, b) =>
        !params.isShowPrice?.value
          ? 0
          : a?.bestPrice || (a?.bestDelivery && !b?.bestPrice)
            ? -1
            : 1,
      ),
  );

  const offersItemsBySortState = computed<Array<IProductResultedOffer>>(() =>
    orderBy(
      cleanOffersItems.value,
      offersSortState.value?.field === EProductSortField.MaxDeliveryDays
        ? [EProductSortField.MaxDeliveryDays, EProductSortField.MinDeliveryDays]
        : [offersSortState.value?.field],
      offersSortState.value?.field === EProductSortField.MaxDeliveryDays
        ? [offersSortState.value?.order, offersSortState.value?.order]
        : [offersSortState.value?.order],
    ),
  );

  const currentOffersItems = computed(
    () => !offersSortState.value
      ? cleanOffersItems.value
      : offersItemsBySortState.value,
  );

  const sortedOffersItems = computed(() => (
    currentOffersItems.value.filter((el) =>
      params.isErp
        ? (el.stock >= Number(params.productsCount.value) && el.minDeliveryDays > 0) || !el.available
        : el.stock >= Number(params.productsCount.value) || !el.available,
    )
  ));

  const offersCount = computed(
    () => (sortedOffersItems.value || []).length || 0,
  );

  return {
    cleanOffersItems,
    offersItemsBySortState,
    offersSortState,
    currentOverallPrice,
    sortedOffersItems,
    offersCount,
  };
}
