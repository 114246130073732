<template>
  <div :class="['mm-offers-label', labelClass]">
    {{ props.text }}
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  text: string;
  labelClass: string;
}>();
</script>
<style lang="scss" scoped>
.mm-offers-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 21px 3px;
  border-radius: 6px 0 16px;
  min-width: max-content;
}
</style>
