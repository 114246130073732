<template>
  <Drawer
    :visible="visible"
    content-class="mm-offer"
    header-class="mm-offer__header"
    rounded
    :position="EDrawerPosition.Bottom"
    :width="(DocumentBreakpointsUtils.getIsRenderedMobile() && '90%') || undefined"
    :z-index="isErp ? 41 : 56"
    @close="$emit('close')"
  >
    <template #title>
      <OfferLabel
        v-if="declinedItemsCount"
        text="Ранее отклонено"
        label-class="offers-table__label mm-block-light-green"
      />
      <SvgIcon
        v-else-if="!!offerTypeIcon"
        class="mm-offer-svg"
        :src="offerTypeIcon"
      />
    </template>
    <div class="mm-offer-item-inner">
      <p class="mm-headline-3">
        {{ props.itemTitle }}
      </p>
      <BasketWarningRow
        v-if="declinedItemsCount"
        hide-select-button
        :warning-type="EWarningMessageType.Simple"
        :text="`Поставщик не подтвердил заказ данного товара в количестве <span>${declinedItemsCount} ${unitName}</span>`"
      />
      <div class="mm-product-info">
        <div class="mm-product-info-items">
          <div class="mm-product-info-item">
            <p class="mm-headline-5">
              {{ props.offer?.available ? 'в наличии' : 'под заказ' }}
            </p>
            <p
              v-if="props.offer?.available"
              class="mm-caption-regular"
            >
              {{ props.offer?.stock }} {{ unitName }}
            </p>
          </div>
          <div class="mm-product-info-item">
            <p class="mm-headline-5">
              {{
                isUserLoggedIn ? OfferMapper.mapDeliveryDays([props.offer?.minDeliveryDays,
                                                              props.offer?.maxDeliveryDays]) : '-'
              }}
            </p>
            <p class="mm-caption-regular">
              {{ isUserLoggedIn ? deliveryPrice : '-' }}
            </p>
          </div>
          <div class="item-wrapper">
            <div class="mm-product-info-item">
              <p class="mm-headline-5">
                {{ oneItemPrice }} ₽
              </p>
              <p class="mm-caption-regular">
                1 {{ unitName }}
              </p>
            </div>
            <BaseTooltip
              v-if="isConfiguredTerms"
              arrow
              hover
              offset-distance="4"
              class="mm-tooltip mm-tooltip--base mm-offer-terms__tooltip"
            >
              <SvgIcon src="navigation/info-outline" />
              <template #content>
                <div class="mm-offer-terms__popup">
                  <div class="mm-offer-term__wrapper">
                    <p class="mm-offer-terms__title">
                      Цена в зависимости от объема
                    </p>
                    <ProductCharacteristic
                      low-opacity
                      class="mm-offer-terms__product-characteristic"
                      :characteristics="shippingItemCost"
                    />
                  </div>
                </div>
              </template>
            </BaseTooltip>
          </div>
        </div>
        <ProductCharacteristic
          class="product-characteristic"
          :characteristics="shipping"
        />
      </div>
      <div
        v-if="isConfiguredTerms || offerDetails?.saleNotes"
        class="mm-offer-bottom-wrapper"
      >
        <p class="mm-headline-4">
          Условия покупки
        </p>
        <div
          v-if="isConfiguredTerms"
          class="mm-offer-terms"
        >
          <div class="mm-offer-term">
            <p class="mm-headline-5">
              Цена товара в зависимости от объема
            </p>
            <ClientOnly>
              <ProductCharacteristic
                class="product-characteristic"
                :characteristics="shippingItemCost"
              />
            </ClientOnly>
          </div>
        </div>
        <template v-if="additionalParams">
          <p class="mm-headline-4">
            Дополнительная информация
          </p>
          <div class="mm-offer-terms">
            <div class="mm-offer-term">
              <ClientOnly>
                <ProductCharacteristic
                  class="product-characteristic"
                  :characteristics="additionalParams"
                />
              </ClientOnly>
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="offerDetails?.saleNotes"
        class="mm-offer-description"
      >
        <p class="mm-headline-4">
          Описание от продавца
        </p>
        <p
          class="mm-body-regular-s"
          v-html="sanitize(offerDetails?.saleNotes)"
        />
      </div>
    </div>
    <template #footer>
      <div class="mm-offer__footer">
        <div class="mm-offer__summary">
          <div class="mm-offer__summary-item">
            ИТОГО
          </div>
          <div class="mm-offer__summary-item">
            <div class="d-flex align-items-center">
              {{ StringsHelper.toPriceString(props.offer.costWithVatAndDelivery) }} ₽
              <BaseTooltip
                v-if="isConfiguredTerms"
                arrow
                hover
                class="mm-tooltip mm-tooltip--base mm-offer-terms__tooltip"
                offset-distance="4"
              >
                <div class="mm-offer__summary-icon d-flex align-items-center">
                  <SvgIcon src="navigation/info-outline" />
                </div>
                <template #content>
                  <div class="mm-offer-terms__popup">
                    <div class="mm-offer-term__wrapper">
                      <p class="mm-offer-terms__title">
                        Цена в зависимости от объема
                      </p>
                      <ProductCharacteristic
                        low-opacity
                        class="mm-offer-terms__product-characteristic"
                        :characteristics="shippingItemCost"
                      />
                    </div>
                  </div>
                </template>
              </BaseTooltip>
            </div>
          </div>
        </div>
        <div class="mm-offer__actions">
          <QuantityInput
            v-model="itemsQuantity"
            :min-value="0"
            :max-value="props.offer?.stock"
            :field-validation="numericValue"
            :precision="UnitOkeiHelper.getModelUnitPrecision(productDetails?.unit)"
            :modifier="props.offer?.stepQuantity"
          />
          <AddToCartButton
            :quantity="preparedQuantity"
            :offer-id="props.offer?.id"
            :loading="loading"
            :disabled-add-button="disabledAddToCart"
            :is-conflict-bases-category="props.isConflictBasesCategory"
            :unit-name="unitName"
            :has-mapping="props.hasMapping"
            :is-disabled-by-client-category-code="props.isDisabledByClientCategoryCode"
            :disabled-by-offer-rules="OfferMapper.isOfferDisabledByRules(props.offer, preparedQuantity)"
            @on-add-to-cart="onAddToCart"
            @on-auth-link-click="onAuthLinkClick"
          />
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import SvgIcon from 'shared/components/SvgIcon.vue';
import IProductCharacteristic from 'shared/models/productCharacteristic.model';
import { IProductResultedOffer, IProductOfferDetails, IProductDetails } from 'shared/models/product.model';
import QuantityInput from 'shared/components/QuantityInput.vue';
import BaseTooltip from 'shared/components/BaseTooltip.vue';
import { StringsHelper } from 'shared/utils/strings.util';
import { useField } from 'vee-validate';
import Drawer from 'shared/components/Drawer.vue';
import { OfferMapper } from 'shared/utils/offerMapper.util';
import { useUserStore } from 'store/user.store';
import { DocumentBreakpointsUtils } from 'shared/utils/documentBreakpoints.util';
import { EDrawerPosition } from 'shared/enums/drawer.enum';
import { storeToRefs } from 'pinia';
import AddToCartButton from './AddToCartButton.vue';
import { EWarningMessageType } from 'shared/enums/warningMessageType.enum';
import BasketWarningRow from 'components/basket/BasketWarningRow.vue';
import OfferLabel from 'components/OfferLabel.vue';
import { sanitize } from 'shared/directives/sanitize';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { UnitOkeiHelper } from 'utils/unitOkeiHelper.util';

const userStore = useUserStore();
const { isUserLoggedIn } = storeToRefs(userStore);

const props = defineProps<{
  visible?: boolean;
  offer: IProductResultedOffer;
  offerDetails?: IProductOfferDetails;
  quantity: number;
  itemTitle: string;
  vatInfo: { label: string, value: number };
  deliveryInfo: { label: string, value: number };
  loading?: boolean;
  disabledAddToCart?: boolean;
  isConfiguredTerms?: boolean;
  productDetails: IProductDetails;
  isConflictBasesCategory?: boolean;
  unitName: string;
  hasMapping?: boolean;
  isDisabledByClientCategoryCode?: boolean;
  isErp?: boolean;
  declinedItemsCount?: number;
  showOfferQuantity?: number;
  ociMode?: boolean;
}>();

const emits = defineEmits<{
  (e: 'onAddCount', value: number): void;
  (e: 'close'): void;
  (e: 'onAddToCart'): void;
}>();

const itemsQuantity = ref<number>(props.quantity || 1);
const numericValue = useField('');

const watchSubscription = new WatchSubscription();
const shipping = computed<Array<IProductCharacteristic>>(() => OfferMapper.mapShipping(props.offer, props.quantity, props.unitName, UnitOkeiHelper.getModelUnitPrecision(props.productDetails?.unit)));
const additionalParams = computed<Array<IProductCharacteristic>>(() => [
    {
      label: 'Страна производства',
      value: props.offerDetails?.countryOfOrigin?.name || props.productDetails?.countryOfOrigin || '-',
    },
    ...(props.offerDetails?.parameters?.map((param) => ({ label: param.name, value: `${param.value} ${param.unit}` })) || []),
  ],
);
const shippingItemCost = computed<Array<IProductCharacteristic>>(() => OfferMapper.mapTermsPriceToChars(props.offer?.termsPrice, props.unitName));
const deliveryPrice = computed(() => OfferMapper.mapDeliveryPrice(props.offer?.deliveryPrice));
const oneItemPrice = computed(() => StringsHelper.toPriceString(props.offer?.price));
const offerTypeIcon = computed(() => {
  if (!props.offer?.bestPrice && !props.offer?.bestDelivery) {
    return '';
  }

  if (!props.ociMode) {
    return props.offer?.bestPrice ? 'indicators/best-price' : 'indicators/fast-shipping';
  }

  return props.offer.bestDelivery ? 'indicators/fast-shipping' : '';
});

const preparedQuantity = computed(
  () => props.showOfferQuantity
    ? props.offer.quantity
    : OfferMapper.mapOfferQuantity(
        props.offer.stepQuantity,
        props.offer.minQuantity,
        +props.quantity || 1,
        UnitOkeiHelper.getModelUnitPrecision(props.productDetails?.unit),
      ),
);

function onAuthLinkClick() {
  emits('close');
}

function onAddToCart() {
  emits('onAddToCart');
  emits('close');
}

watchSubscription.add(
  watch(
    () => props.quantity,
    () => {
      itemsQuantity.value = props.quantity || 1;
    },
  ),

  watch(
    itemsQuantity,
    () => {
      emits('onAddCount', itemsQuantity.value);
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.mm-tooltip.mm-tooltip--base) {
  width: unset !important;
}

:deep(.drawer__content-header.mm-offer__header) {
  .mm-offers-label.offers-table__label.mm-block-light-green {
    font-size: 12px;
  }
}

.mm-product-characteristic {
  width: 100%;
}

.mm-offer-terms {
  &__title {
    margin: 0;
    color: $text-white !important;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 20px;
  }

  &__product-characteristic {
    :deep(.mm-product-characteristic-label) {
      background-color: transparent;
    }

    :deep(.mm-product-characteristic-value) {
      background-color: transparent;
      color: $text-white !important;
      font-weight: 500 !important;
    }
  }
}

:deep(.mm-offer-terms__tooltip) {
  .popper {
    max-width: 200px;
    min-width: 200px;
  }
}

.mm-offer-item-inner {
  &:has(.mm-basket-warning-row.mm-basket-warning-row--simple) {
    .mm-headline-3 {
      margin-bottom: 32px;
    }

    .mm-basket-warning-row.mm-basket-warning-row--simple {
      margin-bottom: 32px;

      :deep(.mm-basket-warning-row__text) > span > span {
        font-weight: 500;
      }
    }
  }
}

:deep(.mm-add-cart__btn) {
  .btn-primary {
    user-select: none;
    height: 40px;
    display: flex;
    justify-content: center;
  }

  .mm-offers-expanded__added-cart {
    display: flex;
    align-items: center;
    margin-left: 8px;

    &-text {
      font-size: 14px;
      line-height: 20px;
      margin-left: 12px;
    }
  }
}

@media (max-width: 767px) {
  .mm-offer__actions {
    width: 100%;
    justify-content: space-between;

    .mm-quantity {
      max-width: 155px;
    }
  }
}
</style>
