<template>
  <div>
    <template v-if="productDetails?.characteristicGroups?.short?.items?.length">
      <h2>Характеристики</h2>

      <ProductDetailsCharacteristics :groups="productDetails?.characteristicGroups" />
    </template>

    <template v-if="isCatalogAvailable">
      <h2>
        {{ classificationAndArticles?.short?.name }}
      </h2>

      <ProductDetailsCharacteristics
        :groups="classificationAndArticles"
      />

      <template v-if="productDetails.description">
        <h2>Описание</h2>
        <div
          class="product-desc"
          v-html="sanitize(productDetails.description)"
        />
      </template>
    </template>

    <template v-else>
      <template
        v-for="(item, index) in availabilityTitles"
        :key="index"
      >
        <h2>{{ item }}</h2>
        <p class="availability-text">
          {{ availabilityText }}
        </p>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { IProductDetails } from 'shared/models/product.model';
import ProductDetailsCharacteristics from 'components/productDetails/ProductDetailsCharacteristics.vue';
import { sanitize } from 'shared/directives/sanitize';
import { useCatalog } from 'composables/useCatalog';

const props = defineProps<{
  productDetails: IProductDetails;
}>();

const availabilityTitles = ['Классификация и артикулы', 'Описание'];
const availabilityText = 'Доступно после заключения договора';
const { isCatalogAvailable } = useCatalog();

const classificationAndArticles = computed(() => ({ short: props.productDetails?.characteristicGroups?.classificationAndArticles }));
</script>

<style lang="scss">
@import 'styles/base/common/variables';

.availability-text {
  @include text-r14;

  color: $color-green-muted;
}

.models {
  &-main-characteristics {
    margin-bottom: 40px;
  }

  &-class-characteristics {
    margin-bottom: 45px;
  }

  &-page {
    h2 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: 500;
      color: $text-black;
      font-size: 24px;

      & + div {
        margin-bottom: 32px;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }

    &__h4 {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 1279px) {
  h2 {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .models-page h2 + div {
    font-size: 14px;
    line-height: 20px;
  }

  :deep(.models-main-characteristics) {
    .mm-product-characteristic-wrapper {
      &__label-column {
        flex: 1;

        & + .mm-product-characteristic-filler {
          display: none;
        }

        .mm-product-characteristic-label {
          max-width: 100%;
          white-space: normal;
          width: min-content;
        }
      }

      &__value-column {
        flex-basis: 30%;

        .mm-product-characteristic-value {
          white-space: normal;
        }
      }
    }
  }

  :deep(.mm-product-characteristic-item) {
    .mm-product-characteristic-wrapper {
      flex-basis: 100%;
    }

    .mm-product-characteristic-value {
      width: auto;
      text-align: right;
      max-width: 50%;
    }

    .mm-product-characteristic-label {
      width: auto;
      text-align: left;
      max-width: 50%;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  :deep(.models-main-characteristics) {
    .mm-product-characteristic-wrapper {
      &__label-column {
        flex: 1;

        & + .mm-product-characteristic-filler {
          display: none;
        }

        .mm-product-characteristic-label {
          max-width: 100%;
          white-space: normal;
          width: min-content;
        }
      }

      &__value-column {
        flex-basis: 30%;

        .mm-product-characteristic-value {
          white-space: normal;
        }
      }
    }
  }
}
</style>
