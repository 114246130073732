import { IProductCurrentOverallPrice, IProductResultedOffer, ITermItem } from '../models/product.model';
import IProductCharacteristic from '../models/productCharacteristic.model';
import { StringsHelper } from './strings.util';
import { EWarrantyTypes } from '../models/offer.model';
import { EOfferCalcTypes } from '../enums/offerCalcTypes.enum';
import { EOfferPriceType } from '../enums/offersPriceType.enum';
import { formatPrice } from './priceValueFormat.util';
import { formatQuantity } from 'shared/utils/formatQuantity.util';
import { DecimalNumberUtil } from './decimalNumber.util';
import {ERuLocales} from '../enums/ruLocales.enum';

export class OfferMapper {
  static mapShipping(offer: IProductResultedOffer, quantity: number, unitName = 'шт', precision: number): Array<IProductCharacteristic> {
    const shipCharsArray: Array<IProductCharacteristic> = [];

    shipCharsArray.push({
      label: 'Квант поставки',
      value: offer.stepQuantity ? `${formatQuantity(offer.stepQuantity, precision, unitName)}` : 'нет',
      valueWarn: !OfferMapper.isOfferStepQuantityMultipleQuantity(offer.stepQuantity, quantity),
    });

    shipCharsArray.push({
      label: 'Минимальная партия',
      value: offer.minQuantity ? `${formatQuantity(offer.minQuantity, precision, unitName)}` : 'нет',
      valueWarn: !OfferMapper.isOfferMinQuantityLessThenQuantity(offer.minQuantity, quantity),
    });

    shipCharsArray.push({
      label: 'Минимальная сумма заказа',
      value: offer.minSum ? `${StringsHelper.toPriceString(offer.minSum)} ₽` : 'нет',
    });

    return shipCharsArray;
  }

  static mapTermsPriceToChars(terms: Array<ITermItem>, unitName = 'шт'): Array<IProductCharacteristic> {
    return (terms || []).map((term) => ({
      label: `от ${term.interval} ${unitName}`,
      value: formatPrice(term.price),
    }));
  }

  static mapTermsDeliveryToChars(terms: Array<ITermItem>): Array<IProductCharacteristic> {
    return terms.map((term) => ({
      label: `от ${StringsHelper.toPriceString(term.interval)} ₽`,
      value: term.price ? `${StringsHelper.toPriceString(term.price)} ₽` : 'бесплатно',
    }));
  }

  static mapDeliveryPrice(price?: number): string {
    return price || price === 0 ? `${StringsHelper.toPriceString(price)} ₽` : '-';
  }

  static mapDeliveryDays(days?: Array<number>): string {
    const { t } = useI18n();

    if (days?.length) {
      if (!days[0] && !days[1]) {
        return '-';
      }

      if (days[0] === days[1]) {
        return t(EWarrantyTypes.Day, days[0]);
      }

      return `${days[0]}-${t(EWarrantyTypes.Day, days[1])}`;
    }

    return '-';
  }

  static mapDeliveryDaysBeforeOnly(days?: number): string {
    const { t } = useI18n();
    return days
      ? `до ${t(ERuLocales.DayAlternate, days)}`
      : '-';
  }

  private static getClosestToMinMultipleToStepQuantity(stepQuantity: number, minQuantity: number, unitPrecision: number): number {
    return minQuantity < stepQuantity
        ? stepQuantity
        : DecimalNumberUtil.floor(Math.floor(minQuantity / stepQuantity) * stepQuantity, unitPrecision || 0);
  }

  public static mapOfferQuantity(stepQuantity: number, minQuantity: number, count: number, unitPrecision: number) {
    if (minQuantity > count) {
      return minQuantity;
    }

    if (stepQuantity && minQuantity) {
      const closestToMinMultipleToStepQuantity = this.getClosestToMinMultipleToStepQuantity(stepQuantity, minQuantity, unitPrecision);

      return count > closestToMinMultipleToStepQuantity
        ? this.getClosestToMinMultipleToStepQuantity(stepQuantity, count, unitPrecision)
        : closestToMinMultipleToStepQuantity;
    }

    /**
     * Если нет кванта и есть минимальное кол-во -- вернуть минимальное кол-во
     * Если есть квант и нет минимального кол-ва -- вернуть count
     */
    return !stepQuantity && minQuantity ? minQuantity : count;
  }

  public static getMaxQuantity(stepQuantity: number, minQuantity: number, needQuantity: number, unitPrecision: number) {
    if (stepQuantity && minQuantity) {
      const closestToMinMultipleToStepQuantity = this.getClosestToMinMultipleToStepQuantity(stepQuantity, minQuantity, unitPrecision);

      return needQuantity > closestToMinMultipleToStepQuantity
          ? this.getClosestToMinMultipleToStepQuantity(stepQuantity, needQuantity, unitPrecision)
          : closestToMinMultipleToStepQuantity;
    }

    /**
     * Если нет кванта или минимального кол-ва поставки -> возвращаем количество потребности
     */
    return needQuantity;
  }

  public static getCurrentOverallPrice(resultedOffer?: IProductResultedOffer, priceType?: Array<EOfferCalcTypes>): IProductCurrentOverallPrice {
    if (!priceType) {
      return { price: resultedOffer?.costNet ?? 0, type: EOfferPriceType.CostNet };
    }

    if ([EOfferCalcTypes.Delivery, EOfferCalcTypes.Price, EOfferCalcTypes.Vat].every((item) => priceType.includes(item))) {
      return { price: resultedOffer?.costWithVatAndDelivery ?? 0, type: EOfferPriceType.CostWithVatAndDelivery };
    }

    if ([EOfferCalcTypes.Delivery, EOfferCalcTypes.Price].every((item) => priceType.includes(item))) {
      return { price: resultedOffer?.costWithDelivery ?? 0, type: EOfferPriceType.CostWithDelivery };
    }

    if ([EOfferCalcTypes.Vat, EOfferCalcTypes.Price].every((item) => priceType.includes(item))) {
      return { price: resultedOffer?.costWithVat ?? 0, type: EOfferPriceType.CostWithVat };
    }

    return { price: resultedOffer?.costNet ?? 0, type: EOfferPriceType.CostNet };
  }

  public static isOfferDisabledByRules(offer: IProductResultedOffer, quantity: number): boolean {
    return !OfferMapper.isOfferStepQuantityMultipleQuantity(offer.stepQuantity, quantity)
      || !OfferMapper.isOfferMinQuantityLessThenQuantity(offer.minQuantity, quantity);
  }

  public static isOfferStepQuantityMultipleQuantity(offerStepQuantity: number, quantity: number): boolean {
    return (quantity >= offerStepQuantity) && DecimalNumberUtil.isDivisibleWithoutRemainder(quantity, offerStepQuantity);
  }

  public static isOfferMinQuantityLessThenQuantity(offerMinQuantity: number, quantity: number): boolean {
    return offerMinQuantity <= quantity;
  }
}
