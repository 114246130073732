<template>
  <div class="mm-similars-analogs">
    <ClipLoader
      v-if="pending"
      caption="Загружаются аналоги и похожие товары"
    />

    <div
      v-else
      class="mm-similars-analogs-wrapper"
    >
      <RelatedItems
        v-if="products?.substitutions?.length"
        label="Аналоги"
        :products="products?.substitutions"
        :product-click-fn="productClickFn"
        :oci-mode="ociMode"
        :is-show-price="isShowPrice"
      />
      <RelatedItems
        v-if="products?.similars?.length"
        label="Похожие товары"
        :products="products?.similars"
        :product-click-fn="productClickFn"
        :oci-mode="ociMode"
        :is-show-price="isShowPrice"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import RelatedItems from 'components/similars-and-analogs/RelatedItems.vue';
import { IProductRelated, IRelatedProductClickEvent } from 'shared/models/product.model';
import ClipLoader from 'shared/components/ClipLoader.vue';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import { ECookieKeys } from 'shared/enums/cookieKeys';
import { CookieManager } from 'shared/utils/cookieManager.util';
import { EFetchKey } from 'enums/fetchKey.enum';

const props = defineProps<{
  // Флаг для ерп дравера
  isErp?: boolean;
  // Флаг для ОСИ
  ociMode?: boolean;
  // код ОКЕИ для поиска
  okeiCode?: string;
  // флаг для поиска, используется в erp
  hasMtrCode?: boolean;
  // id клиента
  clientId?: number;
  // строка fiasId базиса
  regionId?: string;
  // id базиса для поиска
  basisId?: number;
  // id модели продукта для которой выполнять поиск
  modelId: number;
  // функция перехода на выбранный продукт
  productClickFn?: (product: IRelatedProductClickEvent) => void | Promise<void>;
  // Ключ для хранения данных
  fetchKey?: string;
  // Флаг показывать ли цены
  isShowPrice?: boolean;
}>();

const {
  data: products,
  pending,
} = await useLazyAsyncData<IProductRelated>(
  props.fetchKey || `${EFetchKey.ProductSimilarsAndAnalogs}-${props.modelId}`,
  async () => {
    try {
      return await PimCatalogService.getProductRelated(
        [props.modelId],
        props.regionId,
        props.okeiCode,
        props.ociMode ? CookieManager.getCookieObject(ECookieKeys.OciClientId) : props.clientId,
        props.ociMode ? undefined : props.hasMtrCode,
        props.ociMode,
        props.ociMode,
        props.basisId,
        props.ociMode,
      );
    } catch (error) {
      clientSentry.captureComponentException(
        error,
        'SimilarsAndAnalogs',
        {
          extra: props,
        },
      );
    }

    return {
      similars: [],
      substitutions: [],
    };
  },
);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-similars-analogs {
  position: relative;
  min-height: 100px;
  margin-left: -32px;
  margin-bottom: 40px;

  .mm-clip-loader {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;

    :deep(.mm-clip-loader__caption) {
      color: $text-black;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    :deep(.mm-clip-loader__clip) {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-similars-analogs {
    margin-bottom: 48px;
    margin-top: 48px;
  }

  .mm-similars-analogs-wrapper {
    gap: 48px;
  }
}

@media screen and (max-width: 767px) {
  .mm-similars-analogs {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .mm-similars-analogs-wrapper {
    gap: 40px;
  }
}
</style>
